@import './AppVariable.scss';
body{
    font-family: 'Ubuntu', sans-serif;
}
.spin-loader-adjust{
    display: flex;
    justify-content: center;
    align-self: center;
    min-height: 700px;
    align-items: center;
    background: $primary;
    position: absolute;
    left:0px;
    right:0px;
    top:0px;
    bottom:0px;
}
.spin-loader-adjust .spinner-border{
    width: 5rem;
    height: 5rem;
}
.logo{
    width:180px;
}
.navbar{
    background: $primary;
}
.navbar-light .navbar-nav .nav-link{
    color: $secondary !important;
    font-size: 16px;
}
.Banner-block{
    background-image: url('../assets/oxygen homes apartment bachupally.jpg');
    background-size: cover;
    padding-top:180px;
    padding-bottom: 80px;
    min-height: auto;
    display: flex;
    align-items: center;
    background-attachment: fixed;
}
.rk-logo{
    margin-bottom: 10px;
}
.banner-content{
    background: $bg-light;
    padding:20px;
    color: $white;
    margin-top:0px;
}
.banner-content img{
    width: 150px!important;
    height: auto !important;
}
.banner-title{
    font-size: 32px;
}
.banner-title span{
    color: $primary;
}
.mt-3{
    margin-top:30px !important;
}
.mb-3{
    margin-bottom: 30px !important;
}
.mt-5{
    margin-top:50px !important;
}
.mb-5{
    margin-bottom: 50px !important;
}
.btn-primary{
    background: linear-gradient(to bottom, $white 50%, $primary 50%) no-repeat scroll right bottom / 100% 210% $primary !important;
    color: $light-grey;
    padding-left: 40px;
    padding-right: 40px;
    font-size: 16px;
    border-radius: 0px !important;
    font-weight: 700;
    padding-top:15px;
    border:none !important;
    box-shadow: none !important;
    padding-bottom: 15px;
}
.btn-primary:hover{
    background-position: right top !important;
    color: $secondary !important;
}
.banner-rera{
    font-size: 20px;
}
.btn-explore{
    background:transparent !important;
}
.btn-explore img{
    width: 24px;
}
.about-us{
    background: $white;
    padding-top: 120px;
    padding-bottom: 120px;
}
.about-us-title{
    color: $secondary;
    letter-spacing: 2px;
    font-size: 32px;
}
.about-us-content p{
    font-size: 16px;
    line-height: 1.5em;
    margin-top: 30px;
    color: $p-color;
    text-align: justify;
}
.about-us-left-adjust{
    padding-left: 60px;
}
.learn-more{
    color: $primary;
    padding-left: 0px;
    font-size: 16px;
}
.learn-more:hover{
    color: $secondary !important;
}
.btn-read-more{
    background: linear-gradient(to bottom, $secondary 50%, $primary 50%) no-repeat scroll right bottom / 100% 210% $primary !important;
    font-weight: bold;
    font-style: normal;
    font-size: 22px;
    line-height: 1.2em;
    text-transform: uppercase;
    position: absolute;
    /* top: 40%; */
    right: 10px;
    text-align: inherit;
    bottom: 12%;
}
.btn-read-more span{
    font-style: italic;
    font-size: 16px;
    text-transform: capitalize;
    line-height: initial;
}
.image-block{
    padding-left: 30px;
    padding-right: 30px;
    position: relative;
}
.btn-read-more:hover{
    -webkit-transition: all ease .3s;
    -moz-transition: all ease .3s;
    -ms-transition: all ease .3s;
    -o-transition: all ease .3s;
    transition: all ease .3s;
    background-position: right top !important;
    color: $white !important;
}
.stars-project{
    background: $secondary;
}
.star-value{
    font-weight: 400;
    font-size: 32px;
    line-height: 1.1em;
    margin: 0;
    text-align: left;
    color: $primary;
    margin-bottom: 0px;
}
.star-label{
    font-weight: 400;
    font-size: 16px;
    color: $white;
    margin-bottom: 0px;
}
.star-project-section{
    padding: 30px;
}
.bordrer-right-color{
    border-right: 1px solid $light-grey;
}
.count-section{
    color: $count-section;
    font-style: normal;
    font-size: 6rem;
    line-height: 1.2em;
    opacity: 0.3;
    filter: alpha(opacity=30);
    position: absolute;
    z-index: 0;
    top: -40px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}
.section-title{
    font-size: 2rem;
    letter-spacing: 2px;
    font-weight: 600;
}
.sc_promo_text{
    position: relative;
}
.bg_white_background{
    padding-top:120px;
    padding-bottom: 120px;
    background-image: url('../assets/bg_white_background.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.mt-7{
    margin-top:70px;
}
.mb-7{
    margin-bottom: 70px;
}
.photo-tour ul {
    list-style: none;
    text-align: center;
    margin-top:0px;
}
.photo-tour ul li {
    font-size: 16px;
    color: $p-color;
}
.photo-tour ul li span{
    color: $secondary;
    font-size: 16px;
}
.mt-120{
    margin-top:60px;
}
/** Custom Responsive Slider **/
.next-swipe-arrow{
    position: absolute;
    right: 0px;
    background: $secondary;
    height: 60%;
    top: 5rem;
    bottom: 8rem;
    width: 7rem;
    border-color: $secondary;
    color: $white;
}
.prev-swipe-arrow{
    position: absolute;
    left: 0px;
    background: $secondary;
    height: 60%;
    top: 5.5rem;
    bottom: 8rem;
    width: 7rem;
    border-color: $secondary;
    color: $white;
}
.swiper-slider-custom{
    position: relative;
}
.photo-tour .slick-slide{
    padding:0.8rem 7rem;
    position: relative;
}
.photo-tour .next-swipe-arrow .next-arrow-icon, .photo-tour .next-swipe-arrow .prev-arrow-icon{
    font-size: 16px;
}
.prev-arrow-icon{
    font-size: 16px;
}
.photo-tour .next-swipe-arrow:hover, .photo-tour .prev-swipe-arrow:hover{
    background: $primary;
    color: $secondary;
    border:none !important;
}
.bg_white{
    background: $white !important;
}
.apartment-table tr{
    border-bottom: 1px solid $count-section;
}
.apartment-table tr td{
    padding-top:10px;
    padding-bottom: 10px;
}
.pl-sm{
    padding-left: 10px;
}
.partment-title{
    color: $primary;
}
.btn-schedule {
    background: linear-gradient(to bottom, #13162b 50%, #8dc63f 50%) no-repeat scroll right bottom / 100% 210% #8dc63f !important;
    font-weight: 300;
    color: $white;
}
.btn-schedule{
    -webkit-transition: all ease .3s;
    -moz-transition: all ease .3s;
    -ms-transition: all ease .3s;
    -o-transition: all ease .3s;
    transition: all ease .3s;
}
.btn-schedule:hover{
    color: $white !important;
}
.prev-swipe-arrow-appartments{
    position: absolute;
    left: 0px;
    background: #13162b;
    color: #ffffff;
    top:50%;
    padding-bottom: 5px;
    cursor: pointer;
}
.prev-swipe-arrow-appartments .slider_controls_label, .next-swipe-arrow-appartments .slider_controls_label{
    padding-right: 10px;
    padding-left: 10px;
}
.next-swipe-arrow-appartments{
    position: absolute;
    right: 0px;
    background: #13162b;
    color: #ffffff;
    top:50%;
    padding-bottom: 5px;
    cursor: pointer;
}
.next-swipe-arrow-appartments .slider_controls_label .next-arrow-icon,.prev-swipe-arrow-appartments .slider_controls_label .prev-arrow-icon{
    font-size: 1.8rem;
    line-height: initial;
}
.height_large{
    height: 6.6667rem !important;
}
.sub-cation{
    color: $primary;
    font-size: 18px;
}
.section-white-title{
    font-size: 32px;
    color: $white;
}
.ApartmentAnimeties{
    background-image: url('../assets/night view of oxygen homes apartments.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 80vh;
    background-position: center;
    padding-bottom: 50px;
}
.height_small{
    height: 2rem !important;
}
@media (min-width: 700px){
.desktop-space-top {
    margin-top: 200px;
}
}
.service-item{
    background: $secondary;
    padding:30px;
    color: $primary;
    cursor: pointer;
}
.service-item:hover{
    color: $white !important;
}
.service-item-title{
    font-size: 14px;
    line-height: initial;
    min-height: 80px;
}
.hover-transition .trx_addons_video_player.with_cover {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.trx_addons_video_player.with_cover .video_mask {
    position: absolute;
    z-index: 3;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    -webkit-transition: all ease .3s;
    -moz-transition: all ease .3s;
    -ms-transition: all ease .3s;
    -o-transition: all ease .3s;
    transition: all ease .3s;
}
.trx_addons_video_player.with_cover .video_hover {
    font-size: 2.8em;
    width: 2.2em;
    height: 2.2em;
    line-height: 2.2em;
    border: none;
}
.trx_addons_video_player.with_cover .video_hover {
    position: absolute;
    z-index: 4;
    left: 50%;
    top: 50%;
    font-size: 3em;
    width: 2em;
    height: 2em;
    line-height: 2em;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    -webkit-transition: all ease .3s;
    -moz-transition: all ease .3s;
    -ms-transition: all ease .3s;
    -o-transition: all ease .3s;
    transition: all ease .3s;
    margin-top: 2em;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    opacity: 0;
}
.trx_addons_video_player.with_cover:hover .video_mask {
    opacity: 1;
}
.trx_addons_video_player.with_cover:hover .video_hover {
    opacity: 1;
    margin-top: 0;
}
.slider-project-1{
    background-image: url('../assets/brundavanam-apartments-pragathi-nagar.jpg');
    position: relative;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100%;
}
.hover-transition{
    max-height: 430px;
    height: 430px;
    position: relative;
}
.project-title{
    color: $secondary;
    font-size: 18px;
    margin-bottom: 10px;
    margin-top:30px;
    text-align: center;
}
.project-content{
    font-size: 16px;
    color:$secondary;
    margin-bottom: 0px;
    text-align: center;
    font-weight: 300;
}
.project-slider .next-swipe-arrow {
    position: absolute;
    height: 55%;
    top: 4rem;
}
.project-slider .prev-swipe-arrow{
    height: 55%;
    top: 4rem;
}
.slider-project-2{
    background-image: url('../assets/gokulam apartment pragathi nagar.jpg');
    position: relative;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100%;
}
.slider-project-3{
    background-image: url('../assets/lorven-villas-pragathi-nagar.jpg');
    position: relative;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100%;
}
.slider-project-4{
    background-image: url('../assets/apple-homes-pragarthi-nagar.jpg');
    position: relative;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100%;
}
.trx_addons_video_player.with_cover .video_hover {
    color: $primary;
}
.trx_addons_video_player.with_cover .video_hover:hover {
    color: #f0f0f0;
    background-color: $primary;
}
.video-embed-code{
    position: absolute;
    opacity: 1;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100%;
    height: 430px;
    max-height: 430px;
    overflow: hidden;
    -webkit-transition: all ease .3s;
    -moz-transition: all ease .3s;
    -ms-transition: all ease .3s;
    -o-transition: all ease .3s;
    transition: all ease .3s;
}
.yt-lite{
    contain: inherit !important;
    position: initial !important;
}
.apartment-neighbourhood{
    background-image: url('../assets/aerial fasat view of oxygen homes apartment.jpg');
    min-height: 100vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}
.neighbourhood-subtitle{
    font-size: 18px;
    color: $primary;
}
.neighbourhood-title{
    font-size: 36px;;
    color: $white;
    letter-spacing: 2px;
    line-height: 1.2em;
}
.text-right{
    text-align: end;
}
.hub-spots{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100% !important;
    background-size: contain;
    z-index: 100;
}
.hub-spot-item{
    position: absolute;
    top: 52%;
    left: 1.5%;
    height: 100%;
}
.hub-spot-item .btn{
    width: 24px;
    height: 24px;
    border: 2px solid rgba(255,255,255,.4);
    color: #fff;
    text-decoration: none;
    position: absolute;
    left: auto;
    top: auto;
    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    border: none;
    -webkit-animation: pulse-white 2s ease-out infinite;
    -moz-animation: pulse-white 2s ease-out infinite;
    animation: pulse-white 2s ease-out infinite;
}
.hub-spot-item .btn span{
    background: #8dc63f;
    width: 10px;
    height: 10px;
    margin: -5px 0 0 -5px;
    border-radius: 100%;
    position: absolute;
    display: block;
    /* background: #FFF; */
    left: 50%;
    top: 50%;
}
.hub-spot-item .btn:hover{
    -moz-transform: scale(1.2,1.2);
    -ms-transform: scale(1.2,1.2);
    -webkit-transform: scale(1.2,1.2);
    transform: scale(1.2,1.2);
    -webkit-animation: none;
    -moz-animation: none;
    animation: none;
}
.hub-spots-position{
    margin: 0 auto;
}
.item-2{
    left:65% !important;
}
.item-3{
    left: 84% !important;
    top: 68% !important;
}
.tooltip-inner{
    padding: 10px;
    font-size: 16px;
    background:$secondary;
    margin-left: -10px;
}
.news-articles .icons {
    position: absolute;
    z-index: 120;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.news-articles .icons span {
    position: absolute;
    z-index: 102;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    display: inline-block;
    width: 6px;
    height: 6px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    -webkit-transition: all ease .5s;
    -moz-transition: all ease .5s;
    -ms-transition: all ease .5s;
    -o-transition: all ease .5s;
    transition: all ease .5s;
    opacity: 0;
    background: $primary;
    filter: alpha(opacity=0);
}
.sc_blogger_item_featured{
    position: relative;
}
.sc_blogger_item_featured:hover .mask {
    background-color: rgba(19, 22, 43, 0.7);
    opacity: 1;
}
.sc_blogger_item_featured:hover .icons span:first-child {
    margin-left: -13px;
}
.sc_blogger_item_featured:hover .icons span {
    opacity: 1;
    filter: alpha(opacity=100);
    display: inline-block;
}
.sc_blogger_item_featured .mask {
    content: ' ';
    position: absolute;
    z-index: 100;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    pointer-events: none;
    filter: alpha(opacity=0);
    -webkit-transition: all ease .5s;
    -moz-transition: all ease .5s;
    -ms-transition: all ease .5s;
    -o-transition: all ease .5s;
    transition: all ease .5s;
}
a:hover{
    color:$primary;
}
.card-body{
    padding:36px;;
    text-align: center;
}
.news-title a{
    text-decoration: none;
    font-size: 22px;;
    color: $secondary;
}
.news-year-title a{
    font-size: 16px;
    text-decoration: none;
    color: $count-section;
}
.card-body p{
    color: $p-color;
    font-size: 16px;
    line-height: inherit;
    margin-top: 30px;
    margin-bottom: 30px;
}
.read-more{
    color: $primary;
    text-decoration: none;
    font-size: 16px;
}
.card{
    border:none !important;
}
.read-more:hover{
    color: $secondary !important;
}
.carousel-control-next, .carousel-control-prev{
    width: 36px;;
    height: 36px;;
    background: $secondary;
    top:50%;
    opacity: 1;
}
.carousel-control-next:hover, .carousel-control-prev:hover{
    background: $primary;
}
.news-articles .carousel-indicators [data-bs-target]{
    width:1rem;
    height: 1rem;
    border-radius: 50%;
    border: 2px solid $white !important;
    background: transparent !important;
    opacity: 1;
}
.news-articles .carousel-indicators .active{
    background: $primary !important;
}
.btn-articles{
    background: linear-gradient(to bottom, #13162b 50%, #8dc63f 50%) no-repeat scroll right bottom / 100% 210% #8dc63f !important;
    white-space: nowrap;
    padding: 1.75em 3em;
    -webkit-transition: all ease .3s;
    -moz-transition: all ease .3s;
    -ms-transition: all ease .3s;
    -o-transition: all ease .3s;
    transition: all ease .3s;
    color: $white;
    font-size: 16px;
    border: none !important;
    outline: none !important;
    text-transform: uppercase;
}
.btn-articles:hover{
    background-position: right top !important;
}
.btn-schedule-tour{
    background: linear-gradient(to bottom, #13162b 50%, #8dc63f 50%) no-repeat scroll right bottom / 100% 210% #8dc63f !important;
    white-space: nowrap;
    padding: 1em 3em;
    -webkit-transition: all ease .3s;
    -moz-transition: all ease .3s;
    -ms-transition: all ease .3s;
    -o-transition: all ease .3s;
    transition: all ease .3s;
    color: $white;
    font-size: 16px;
    border: none !important;
    outline: none !important;
    text-transform: uppercase;
}
.btn-schedule-tour:hover{
    background-position: right top !important;
}
.footer{
    background: $secondary;
}
.footer-title{
    color: $white;
    font-size: 2rem;
}
.footer-content p{
color: $white;
font-size: 16px;
margin-top:30px;
}
.footer-content p a{
    color: $white;
    font-size: 16px;
    text-decoration: none;
}
.social-icons{
    display: flex;
    list-style: none;
    padding-left: 0px;
}
.social-icons li a{
    margin-right: 10px;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top:20px !important;
    padding-bottom: 20px !important;
}
.social-icons li a .fa{
    font-size: 16px;
}
.self-center{
    align-self: center;
}
.form-control{
    color: $white;
    border-color: #1a1e3b;
    background-color: #13162b;
    font-size: 1em;
    line-height: 1.2em;
    font-style: normal;
    padding: 1.4em 1.4em;
}
.form-group{
    margin-bottom: 24px;
}
.form-control:focus{
    background-color: $secondary;
    outline: none !important;
    color: $white;
    box-shadow: none !important;
}
.btn-submit{
    color:$white;
    padding-left: 50px !important;
    padding-right: 50px !important;
    font-weight: 500 !important;
    text-transform: uppercase;
}
.copyRight{
    background: $bg-black;
    padding-top:30px;
    padding-bottom: 30px;
}
.copyRight-title{
    font-size: 16px;
    color: $white;
    text-align: center;
}
.copyRight-title a{
    color: $white;
    text-decoration: none;
}
.carousel-control-next-icon, .carousel-control-prev-icon {
   width: 1rem;
    height: 1rem;
}
/** Mobile Responsive **/
@media (max-width:800px){
.navbar-expand .navbar-toggler {
    display: block!important;
}
.banner-title{
    font-size: 22px;
}
.collapse:not(.show) {
    display: none!important;
}
.navbar-expand .navbar-nav {
    flex-direction: column;
}
.ml-auto, .mx-auto {
    margin-left: 0!important;
}
.navbar-expand{
    flex-wrap: wrap;
}
.navbar-light .navbar-nav .nav-link {
   font-size: 16px;
}
.navbar-expand .navbar-collapse{
    justify-content: left !important;
}
.Banner-block{
    background-position: bottom;
}
.banner-rera {
    font-size: 18px;
}
.about-us-title {
    font-size: 22px;
}
.about-us-content p {
    font-size: 16px;
}
.about-us {
    padding-top: 60px;
    padding-bottom: 00px;
}
.mobile-col-spac{
    margin-top:30px;
}
.btn-read-more {
    font-size: 16px;
}
.bordrer-right-color{
    border-right: none !important;
}
.star-project-section{
    text-align: center;
}
.star-value{
    text-align: center;
}
.count-section {
    font-size: 5rem;
}
.section-title{
    font-size: 22px;
}
.bg_white_background {
    padding-top: 60px;
    padding-bottom: 60px;
}
.apartment-table tr td{
    font-size: 16px;
}
.btn-schedule-tour{
    font-size: 16px;
    text-transform: inherit;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    margin-bottom: 20px;
}
.apartment-neighbourhood{
    min-height: 80vh !important;
}
.ApartmentAnimeties {
    min-height: 60vh;
}
.section-white-title {
    font-size: 2rem;
}
.sub-cation{
    font-size: 16px;
}
.service-item {
    padding: 20px;
    margin-top: 30px;
}
.service-item-title{
    min-height: auto !important;
}
.project-title {
    font-size: 16px;
}
.project-content {
    font-size: 0.8rem;
}
.neighbourhood-title {
    font-size: 22px;
}
.neighbourhood-subtitle {
    font-size: 16px;
    color: #8dc63f;
}
.hub-spots{
    display: none;
}
.card-body {
    padding: 2rem;
}
.news-title a {
    text-decoration: none;
    font-size: 20px;
}
.news-year-title a {
    font-size: 0.8rem;
}
.card-body p {
    font-size: 16px;
}
.read-more {
    font-size: 16px;
}
.btn-articles {
   padding: 1.5em 2em;
   font-size: 16px;
   text-transform: inherit;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    margin-bottom: 20px;
}
.news-content{
    padding-top: 60px !important;
    padding-bottom: 60px !important;
}
.contactForm {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
}
.contact-title{
    font-size: 24px !important;
}
.gallery {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
}
.footer{
    padding-top:40px;
    padding-bottom: 40px;
}
.footer-title {
    font-size: 22px;;
}
.google-map{
    min-height: 400px !important;
}
.btn-primary{
    font-size: 16px;
}
.copyRight-title {
    font-size: 0.8rem;
}
.logo {
    width: 150px !important;
}
body{
    overflow-x: hidden;
}
html{
    overflow-x: hidden;
}
.navbar-expand .navbar-collapse{
    flex-basis: 100% !important;
}
.about-us-left-adjust{
    padding-left: 0px !important;
}
.image-block{
    padding-left: 0px !important;
}
}
@media (max-width:700px) {
    .photo-tour .slick-slide{
        padding:0px !important;
    }
    .mobile-hide{
        display: none;
    }
    .prev-swipe-arrow, .next-swipe-arrow{
        height: 20% !important;
        width: auto !important;
    }
    .project-slider .next-swipe-arrow, .project-slider .prev-swipe-arrow{
        height:20% !important;
        top:10rem;
    }
    .slick-slider{
        margin-top:60px !important;
    }
    .next-swipe-arrow-appartments{
        right:0px !important;
        top:20px !important;
    }
    .next-swipe-arrow-appartments .slider_controls_label .next-arrow-icon, .prev-swipe-arrow-appartments .slider_controls_label .prev-arrow-icon{
        font-size: 16px;
    }
    .prev-swipe-arrow-appartments{
        top:20px !important;
        right:40px !important;
        left:inherit !important;
    }
}
.breadcrumb-section{
    padding-top:200px;
    padding-bottom: 120px;
    background: $secondary;
    text-align: center;
    margin-bottom: 0px;
}
.breadcrumb{
    justify-content: center;
}
.breadcrumb-item a{
    color:#6c757d;
    text-decoration: none;
}
.breadcrumb-title{
    font-size: 32px;
    color:$white;
}
.gallery{
    padding-top:120px;
    padding-bottom: 120px;
}
.gallery img{
    margin-top:30px;
    cursor: pointer;
    webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
}
.gallery img:hover{
    background-color: $gradient-light;
    z-index: 2;
    /* opacity: .6; */
    filter: brightness(0.5);
}
.location-block{
    text-align: center;
}
.location-block img{
    width:4em;
    height: 4em;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}
.location-block p{
    font-size: 16px;
    margin-top: 20px;
}
.location-block p a{
    color: $secondary;
    text-decoration: none;
}
.contactForm{
    padding-top: 80px;
    padding-bottom: 80px;
}
.contact-title{
    font-size: 2rem;
    text-align: center;
    color: $secondary;
    margin-bottom: 30px;
}
.contactForm .form-control{
    color: #a0b2be !important;
    border-color: #f4f9fc !important;
    background-color: #f4f9fc !important;
}
.btn-contact-submit{
    background: linear-gradient(to bottom, #13162b 50%, #8dc63f 50%) no-repeat scroll right bottom / 100% 210% #8dc63f !important;
    text-align: center;
    text-transform: uppercase;
}
.btn-contact-submit:hover{
    color: $white !important;
}
.news-content{
    padding-top:120px;
    padding-bottom: 120px;
}
.news-content-title{
    font-size: 2rem;
    text-align: center;
    color: $secondary;
    margin-bottom: 30px;
}
.news-content p{
    font-size: 16px;
    color: $secondary;
    margin-top:30px;
}
.schedule-tour .modal-header{
    justify-content: flex-end;
    padding: 0px;
    border-bottom: none !important;
}
.schedule-tour .button-close .btn{
    padding: 15px 25px !important;
}
.schedule-tour-title{
    font-size: 1.8rem;
    color: $secondary;
    margin-bottom: 20px;
    text-align: center;
}
.schedule-tour p{
    font-size: 16px;
    color: $secondary;
    text-align: center;
}
.schedule-tour .form-control{
    color: #8b9ba6 !important;
    border-color: #f4f9fc !important;
    background-color: #f4f9fc !important;
}
@media (min-width:700px) {
    .schedule-tour .modal-dialog{
        max-width: 600px;
    }
}
.schedule-tour .form-label sup{
    color: red;
}
.schedule-tour .model-body{
    padding:20px;
}
@media only screen and (min-width:700px) and (max-width:800px) {
    .google-captcha{
        max-width:200px;
    }
    .google-captcha iframe{
        width: 200px;
    }
}
.text-left{
    text-align: inherit;
}
@media (min-width:700px) {
    .quality-life-blocks .card-body p{
        min-height: 125px;
    }
    .min-height-image img{
        min-height: 500px;
    }
}
.why-choose-bachupally{
    padding-top:120px;
    padding-bottom: 120px;
    background-image: url('../assets/WhyChooseUs-GoogleMap-Image.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top right;
}
.why-choose-bachupally .card{
    background-color: #ffffffc2 !important;
}
/* .why-choose-bachupally .neighbourhood-title{
 color: $secondary !important;   
}
.why-choose-bachupally .card{
    background-color: $secondary !important;
}
.why-choose-bachupally .card .news-title{
 color: $primary !important;   
} */
.why-choose-bachupally .card-body p{
    min-height: 300px;
}
.breadcrumb-section p{
    color: $white !important;
    margin-bottom: 0px;
}
.gallery h3{
    color: $secondary !important;
}
@media (min-width:700px){
    .why-choose-bachupally .news-articles {
        margin-top:130px !important;
    }
}